import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Avatar, Box, Button, Grid, TextField} from '@mui/material';
import {
  Columns,
  CustomPagination,
  EmailsSearchFilterToolbar,
  onSortingChange,
  renderCellExpand
} from '../components/dataGrid';
import {emailsActions, permissionsActions} from '../redux/actions';
import {generateEmptyErrorsAndValidation} from '../config/validationRules';
import {validateForm} from '../helpers';
import {
  Attachments,
  EditPageContainer,
  EmailContainer, EmailData, EmailDate, EmailFields, FromEmail,
  MessageData,
  MessageDate,
  MessageHtml,
  MessageOwner,
  MessageText,
  StyledContainer,
  StyledDataGrid,
  StyledGrid,
  StyledLabel,
  StyledLabelLink, Subject, ToEmail,
  UserData,
  UserInfo,
  UserName,
  UserRole
} from '../components/styledComponents';
import strings from '../config/strings';
import {renderAttachment, renderDateTime, renderUser} from '../components/renderFields';
import {grey} from '@mui/material/colors';
import selectOptions from '../config/selectOptions';
import * as dateFns from 'date-fns';
import {People} from '@mui/icons-material';
import {port, url} from '../config/api';

export const EmailsScreen = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const baseActions = emailsActions;
  const model = useSelector(state => state.emails);

  const columns = [
    Columns.createdAt(renderDateTime),
    Columns.sender(),
    Columns.subject(renderCellExpand),
  ];

  const rowSelected = ({id}) => {
    navigate(`/emails/${id}`);
  };

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
      dispatch(baseActions.getList(model.params));
    }, [model.page, model.params.ordering]
  );

  useEffect(() => {
    dispatch(baseActions.updateParams({
      search: searchText,
      offset: 0
    }));
    dispatch(emailsActions.setPage(1));
    dispatch(baseActions.getList(model.params));
  }, [query]);

  const handleFiltersChange = (field) => (value) => {
    let val = value ?? null;
    let updating = {};

    if (field === 'createdFrom' && val !== null) {
      val = new Date(value).setHours(0, 0, 0, 0);
    }

    if (field === 'createdTo' && val !== null) {
      val = new Date(value).setHours(23, 59, 59, 999);
    }

    updating[field] = val;
    dispatch(emailsActions.updateParams(updating));
  };

  const handleFiltersClear = async () => {
    dispatch(emailsActions.setPage(1));
    dispatch(emailsActions.resetParams());
    dispatch(emailsActions.getList(store.getState().emails.params));
  };

  const handleFiltersApply = () => {
    dispatch(emailsActions.getList(store.getState().emails.params));
  };

  return (
    <div style={{height: '90%', width: '100%'}}>
      <StyledDataGrid
        getRowClassName={() => 'styled'}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        columns={columns}
        rows={model.list}
        onSortModelChange={onSortingChange(model, dispatch, baseActions)}
        filterMode="server"
        onRowClick={rowSelected}
        sortingMode="server"
        paginationMode="server"
        loading={model.loading}
        components={{
          Toolbar: EmailsSearchFilterToolbar,
          Pagination: CustomPagination(model, dispatch, baseActions),
        }}
        componentsProps={{
          toolbar: {
            disableAdding: true,
            path: 'emails',
            model: model,
            handleFiltersChange: handleFiltersChange,
            handleFiltersClear: handleFiltersClear,
            handleFiltersApply: handleFiltersApply,
            value: searchText,
            onChange: (event) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
          },
        }}
      />
    </div>
  );
};


const {fieldsInitState, fieldsValidators} = generateEmptyErrorsAndValidation([
  'name',
]);


export const EmailEditScreen = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const navigate = useNavigate();

  const model = useSelector(state => state.emails);

  const [state, setState] = useState(fieldsInitState);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(emailsActions.editItem(updated));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data) {
      dispatch(emailsActions.updateItem(id, data)).then(() => {
        location.reload();
      });
    }
  };

  const handleCancel = () => {
    dispatch(emailsActions.editItem({}));
    navigate('/emails');
  };

  const renderMessage = (email) => {
    return email ? (<EmailContainer key={email?.id}>
      <UserInfo>
          <EmailData>
            <FromEmail><EmailFields>Отправитель:</EmailFields>{email.sender}</FromEmail>
            <ToEmail><EmailFields>Получатель:</EmailFields>{email.recipient}</ToEmail>
            <Subject><EmailFields>Тема:</EmailFields>{email.subject}</Subject>
          </EmailData>
      </UserInfo>
      {email.createdAt && <EmailDate>{dateFns.format(+email.createdAt, 'yyyy.MM.dd HH:mm:SS')}</EmailDate>}
      <MessageData>
        <MessageHtml dangerouslySetInnerHTML={{__html: email.html}}></MessageHtml>
      </MessageData>
      <Attachments>
        {(email.attachments || []).map(e => renderAttachment(e))}
      </Attachments>
    </EmailContainer>) : '';
  };

  useEffect(() => {
      dispatch(emailsActions.getItem(id));
      dispatch(permissionsActions.getList({}));
    }, []
  );

  return (
    <EditPageContainer>
      <StyledContainer maxWidth="xl">
        {model.item ?
          <form onSubmit={handleSubmit}>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={4}>
                {model.item.issue && <Grid item container sx={{
                  marginTop: 4,
                  marginLeft: 2,
                  marginBottom: 2,
                  backgroundColor: grey[100],
                  padding: 2,
                  paddingRight: 4,
                  marginRight: 2,
                  borderRadius: 2
                }}>
                  <StyledGrid sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }} item xs={12}>
                    <StyledLabel sx={{
                      width: '100%'
                    }}>
                      <span>Обращение</span>
                      <StyledLabelLink
                        href={`/issues/${model.item.issue.id}`}
                      >перейти в обращение</StyledLabelLink>
                    </StyledLabel>
                  </StyledGrid>
                  <StyledGrid item xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      size="small"
                      label={strings['email']}
                      name={'email'}
                      value={model.item.issue?.email || ''}
                    />
                  </StyledGrid>
                </Grid>}
                {model.item.user && <Grid item container sx={{
                  marginTop: 4,
                  marginLeft: 2,
                  marginBottom: 2,
                  backgroundColor: grey[100],
                  padding: 2,
                  paddingRight: 4,
                  marginRight: 2,
                  borderRadius: 2
                }}>
                  <StyledGrid sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }} item xs={12}>
                    <StyledLabel sx={{
                      width: '100%'
                    }}>
                      <span>Отправитель</span>
                      <StyledLabelLink
                        href={`/users/${model.item.user.id}`}
                      >перейти в профиль</StyledLabelLink>
                    </StyledLabel>
                  </StyledGrid>
                  <StyledGrid item xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      size="small"
                      label={strings['firstName']}
                      name={'firstName'}
                      value={model.item.user?.firstName || ''}
                    />
                  </StyledGrid>
                  <StyledGrid item xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      size="small"
                      label={strings['lastName']}
                      name={'lastName'}
                      value={model.item.user?.lastName || ''}
                    />
                  </StyledGrid>
                  <StyledGrid item xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      size="small"
                      label={strings['email']}
                      name={'email'}
                      value={model.item.user?.email || ''}
                    />
                  </StyledGrid>
                  <StyledGrid item xs={12}>
                    <TextField
                      disabled={true}
                      fullWidth
                      size="small"
                      label={'Телефон'}
                      name={'number'}
                      value={model.item.user?.number || ''}
                    />
                  </StyledGrid>
                </Grid>}

                {model.item && renderMessage(model.item)}

                <Grid container={true} mt={4} mb={4} justifyContent={'space-around'}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {strings.button.back}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form> : ''
        }
      </StyledContainer>
    </EditPageContainer>
  );
};