import * as issuesConstants from '../constants/issues';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const issuesActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  updateItemNoLoadingEvent,
  editItem,
  removeItem,
  setPage,
  setParams,
  updateParams,
  resetParams,
  clear,
  downloadFile,
  updateReportsParams
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.issues}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issuesConstants.GET_ISSUES};
  }

  function success(payload, totalCount) {
    return {type: issuesConstants.GET_ISSUES_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: issuesConstants.GET_ISSUES_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.issues}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
        return Promise.resolve(res.data);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issuesConstants.GET_ISSUE};
  }

  function success(payload) {
    payload['status'] = payload.status?.id;
    payload['category'] = payload.category?.id;
    payload['_department'] = payload.department; //TODO костыль для async select, переделать
    payload['department'] = payload.department?.id;
    payload['_department2'] = payload.department2; //TODO костыль для async select, переделать
    payload['department2'] = payload.department2?.id;
    payload['_group'] = payload.group; //TODO костыль для async select, переделать
    payload['group'] = payload.group?.id;
    payload['_internalExecutor'] = payload.internalExecutor;//TODO костыль для async select, переделать
    payload['internalExecutor'] = payload.internalExecutor?.id;
    payload['_internalExecutor2'] = payload.internalExecutor2;//TODO костыль для async select, переделать
    payload['internalExecutor2'] = payload.internalExecutor2?.id;
    payload['_area'] = payload.area; //TODO костыль для async select, переделать
    payload['area'] = payload.area?.id;
    payload['unit'] = payload.unit?.id;
    // Костыль для boolean поля переделанного под выпадающий список с пустым значением
    payload['isReceivedReceipt'] = payload.isReceivedReceipt === null ? 'null' : payload.isReceivedReceipt;
    payload['violationType'] = payload.violationType === null ? 'null' : payload.violationType;

    return {type: issuesConstants.GET_ISSUE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: issuesConstants.GET_ISSUE_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.issues}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issuesConstants.ADD_ISSUE};
  }

  function success(payload) {
    return {type: issuesConstants.ADD_ISSUE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: issuesConstants.ADD_ISSUE_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    // Костыль для boolean field в select с выпадающим списком + пустое значение
    data['isReceivedReceipt'] = data['isReceivedReceipt'] === 'null' ? null : data['isReceivedReceipt'];
    data['violationType'] = data['violationType'] === 'null' ? null : data['violationType'];
    return APIHelper.put(
      `${path.issues}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issuesConstants.UPDATE_ISSUE};
  }

  function success(payload) {
    return {type: issuesConstants.UPDATE_ISSUE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: issuesConstants.UPDATE_ISSUE_ERROR, error};
  }
}

function updateItemNoLoadingEvent(id, data) {
  return dispatch => {
    if (data['isReceivedReceipt'] !== undefined) {
      // Костыль для boolean field в select с выпадающим списком из 3 элементов
      data['isReceivedReceipt'] = data['isReceivedReceipt'] === 'null' ? null : data['isReceivedReceipt'];
      data['violationType'] = data['violationType'] === 'null' ? null : data['violationType'];
    }
    return APIHelper.put(
      `${path.issues}/${id}`,
      data
    ).then(
      res => {
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function failure(error) {
    return {type: issuesConstants.UPDATE_ISSUE_ERROR, error};
  }
}

function editItem(data) {
  return {type: issuesConstants.EDIT_ISSUE, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.issues}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issuesConstants.REMOVE_ISSUE};
  }

  function success(payload) {
    return {type: issuesConstants.REMOVE_ISSUE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: issuesConstants.REMOVE_ISSUE_ERROR, error};
  }
}

function setParams(data) {
  return {type: issuesConstants.ISSUES_CHANGE_PARAMS, data};
}

function updateParams(data) {
  return {type: issuesConstants.ISSUES_UPDATE_PARAMS, data};
}

function resetParams() {
  return {type: issuesConstants.ISSUES_RESET_PARAMS};
}

function clear() {
  return {type: issuesConstants.ISSUES_CLEAR};
}

function setPage(data) {
  return {type: issuesConstants.ISSUES_CHANGE_PAGE, data};
}

function updateReportsParams(data) {
  return {type: issuesConstants.ISSUES_UPDATE_REPORTS_PARAMS, data};
}

function downloadFile(url, params, format) {
  return dispatch => {
    dispatch(request(format));
    return APIHelper.get(
      `${url}`,
      {
        params,
        responseType: 'blob',
      }
    ).then(
      res => {
        dispatch(success(res.data));
        const type = res.headers['content-type'];
        const disposition = res.headers['content-disposition'];
        const blob = new Blob([res.data], {
          type: type, encoding: 'UTF-8'
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = disposition.split('filename=')[1] || 'file.txt';
        link.click();
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request(format) {
    return {type: issuesConstants.DOWNLOAD_REPORT, format: format};
  }

  function success(payload) {
    return {type: issuesConstants.DOWNLOAD_REPORT_SUCCESS, payload};
  }

  function failure(error) {
    return {type: issuesConstants.DOWNLOAD_REPORT_ERROR, error};
  }
}