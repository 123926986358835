module.exports = {
  issue: {
    status: [
      {
        id: 0,
        title: 'Создано'
      },
      {
        id: 1,
        title: 'Принято'
      }
    ],
    resolution: [
      {
        id: 'open',
        title: 'Создано'
      },
      {
        id: 'progress',
        title: 'В обработке'
      },
      {
        id: 'answered',
        title: 'Дан ответ'
      },
      {
        id: 'solved',
        title: 'Решено'
      },
      {
        id: 'closed',
        title: 'Закрыто'
      },
    ],
    isReceivedReceipt:[
      {
        id: 'null',
        title: 'Я не знаю'
      },
      {
        id: true,
        title: 'Да'
      },
      {
        id: false,
        title: 'Нет'
      },
    ],
    violationType:[
      {
        id: 'null',
        title: 'Не указан'
      },
      {
        id: 1,
        title: 'Административный'
      },
      {
        id: 2,
        title: 'Уголовный'
      },
    ],

  },
  shiftWorkTime: [
    {
      id: 0,
      title: 'Утро'
    },
    {
      id: 1,
      title: 'День'
    },
    {
      id: 2,
      title: 'Вечер'
    },
  ],
  requiredDocs: [
    {
      id: 1,
      title: 'Паспорт'
    },
    {
      id: 2,
      title: 'ИНН'
    },
    {
      id: 3,
      title: 'СНИЛС'
    },
    {
      id: 4,
      title: 'Медкнижка'
    },
    {
      id: 5,
      title: 'Водительское удостоверение'
    },
  ],
  rruleFrequency: [
    {
      id: 0,
      title: 'Ежегодно'
    },
    {
      id: 1,
      title: 'Ежемесячно'
    },
    {
      id: 2,
      title: 'Еженедельно'
    },
    {
      id: 3,
      title: 'Ежедневно'
    },
    {
      id: 4,
      title: 'Каждый час'
    },
    {
      id: 5,
      title: 'Каждую минуту'
    },
    {
      id: 6,
      title: 'Каждую секунду'
    },
  ],
  rruleByMonth: [
    {
      id: 1,
      title: 'Январь'
    },
    {
      id: 2,
      title: 'Февраль'
    },
    {
      id: 3,
      title: 'Март'
    },
    {
      id: 4,
      title: 'Апрель'
    },
    {
      id: 5,
      title: 'Май'
    },
    {
      id: 6,
      title: 'Июнь'
    },
    {
      id: 7,
      title: 'Июль'
    },
    {
      id: 8,
      title: 'Август'
    },
    {
      id: 9,
      title: 'Сентябрь'
    },
    {
      id: 10,
      title: 'Октябрь'
    },
    {
      id: 11,
      title: 'Ноябрь'
    },
    {
      id: 12,
      title: 'Декабрь'
    },
  ],
  rruleByWeekDay: [
    {
      id: 0,
      title: 'ПН'
    },
    {
      id: 1,
      title: 'ВТ'
    },
    {
      id: 2,
      title: 'СР'
    },
    {
      id: 3,
      title: 'ЧТ'
    },
    {
      id: 4,
      title: 'ПТ'
    },
    {
      id: 5,
      title: 'СБ'
    },
    {
      id: 6,
      title: 'ВС'
    },
  ],
  rruleByMonthDay: [
    {
      id: 1,
      title: '1'
    },
    {
      id: 2,
      title: '2'
    },
    {
      id: 3,
      title: '3'
    },
    {
      id: 4,
      title: '4'
    },
    {
      id: 5,
      title: '5'
    },
    {
      id: 6,
      title: '6'
    },
    {
      id: 7,
      title: '7'
    },
    {
      id: 8,
      title: '8'
    },
    {
      id: 9,
      title: '9'
    },
    {
      id: 10,
      title: '10'
    },
    {
      id: 11,
      title: '11'
    },
    {
      id: 12,
      title: '12'
    },
    {
      id: 13,
      title: '13'
    },
    {
      id: 14,
      title: '14'
    },
    {
      id: 15,
      title: '15'
    },
    {
      id: 16,
      title: '16'
    },
    {
      id: 17,
      title: '17'
    },
    {
      id: 18,
      title: '18'
    },
    {
      id: 19,
      title: '19'
    },
    {
      id: 20,
      title: '20'
    },
    {
      id: 21,
      title: '21'
    },
    {
      id: 22,
      title: '22'
    },
    {
      id: 23,
      title: '23'
    },
    {
      id: 24,
      title: '24'
    },
    {
      id: 25,
      title: '25'
    },
    {
      id: 26,
      title: '26'
    },
    {
      id: 27,
      title: '27'
    },
    {
      id: 28,
      title: '28'
    },
    {
      id: 29,
      title: '29'
    },
    {
      id: 30,
      title: '30'
    },
    {
      id: 31,
      title: '31'
    },
  ],
  profile: {
    sex: [
      {
        id: 'female',
        title: 'Женщина'
      },
      {
        id: 'male',
        title: 'Мужчина'
      },
    ],
    type: [
      {
        id: 1,
        title: 'Суперадмин'
      },
      {
        id: 2,
        title: 'Администратор'
      },
      {
        id: 3,
        title: 'Модератор'
      },
      {
        id: 4,
        title: 'Клиент'
      }
    ],
  },
};